import React, { useEffect, useState } from 'react';
import { Container, Col, Button, Row, Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { BASE_ROUTE, MODEL_ROUTE, STAMP_ROUTE } from '../utils/consts';
import { useNavigate, useParams } from 'react-router-dom';
import FullImageModal from '../components/modals/FullImageModal';
import { fetchOneItem } from '../http/stampAPI';
import coming from '../assets/coming.png';

function RenderPage() {
  const [item, setItem] = useState({
    xlink300px: '', xlink1000px: '', zlink1000px: '', zlink300px: '',
    glink1000px: '', glink300px: '', g2link1000px: '', g2link300px: '',
    g3link1000px: '', g3link300px: '', g4link1000px: '', g4link300px: '',
    g5link1000px: '', g5link300px: '', g6link1000px: '', g6link300px: '',
  });

  const { id } = useParams();
  // console.log(params)

  useEffect(() => {
    fetchOneItem(id).then(data => setItem(data));
  }, []);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const handleClose = () => setShow(false);

  const handleImageClick = (src) => {
    setImageSrc(src);
    setShow(true);
  };


// function Renders() {
  return (
    <Container className="d-flex justify-content-center">
      <Col>
        <Row className='mt-1 md-4 item-text d-flex' 
            style={{ width: '100%' }}>
          <Col md={3} className="justify-content-left mt-2">
            <Button
              className=" mx-2"
              style={{ width: '200px' }}
              variant="outline-light"
              // variant="dark"
              onClick={() => window.open(STAMP_ROUTE + '/' + item.id, '_blank').focus()}
              >
                Назад к клейму
            </Button>
            <Button 
              // className="justify-content-right"
              style={{ width: '80px' }}
              variant="outline-light"
                // variant="dark"
              onClick={() => navigate(MODEL_ROUTE + '/' + item.model_id)}
                    // onClick={() => navigate(MODEL_ROUTE + '/' + model.model_id)} - если получать из отдельных табиц а не из общей
            >3D
            </Button>
          </Col>
          <Col md={6} className="justify-content-left mt-2">
              <Card className="d-flex"
                    // border="secondary"
                    border="light"
                    style={{
                      backgroundColor: "#0b0b0b"
                      // , color: "#fdf0f0ec",
                      , color: "#F8F9FA",
                      borderWidth: "1px"
                    }}
                  >
                    <Card.Header border="secondary" bg={'black'} text={'fcfcdb'} as="h6">Реконструкция легенды: {item.stamp_legend}</Card.Header>
              </Card>
          </Col>
          <Col md={3} className="justify-content-left mt-2">
            <Button
                style={{ width: '300px' }}
                variant="outline-light"
                // variant="dark"
                onClick={() => navigate(BASE_ROUTE)}
            >
              Назад к БД
            </Button>
            


          </Col>
        </Row>

        <Row className='mt-2 md-4 d-flex justify-content-center'>
            <Col md={3} className="justify-content-left mt-2">
              <Image
                className='mt-2'
                variant="top"
                src={
                  (item.xlink1000px && item.xlink300px && item.xlink1000px.startsWith('SF') && item.xlink300px.startsWith('SF'))
                    ? coming
                    : item.xlink300px
                }
                style={{
                  // width: 310,
                  // height: 205,
                  cursor: (
                    item.xlink1000px && item.xlink300px && item.xlink1000px.startsWith('SF') && item.xlink300px.startsWith('SF')
                      ? 'default'
                      : 'pointer'
                  )
                }}
                onClick={() => {
                  if (!(item.xlink1000px && item.xlink300px && item.xlink1000px.startsWith('SF') && item.xlink300px.startsWith('SF'))) {
                    handleImageClick(item.xlink1000px);
                  }
                }}
              />
              <Image
                className='mt-2'
                src={
                  (item.zlink300px && item.zlink1000px)
                    ? item.zlink300px
                    : (item.zlink1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.zlink300px && item.zlink1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.zlink300px && item.zlink1000px) {
                    handleImageClick(item.zlink1000px);
                  }
                }}
              />
              <FullImageModal show={show} handleClose={handleClose} imageSrc={imageSrc} />
            </Col>

            <Col md={3} className="justify-content-left mt-2">
            <Image
                className='mt-2'
                src={
                  (item.glink300px && item.glink1000px)
                    ? item.glink300px
                    : (item.glink1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.glink300px && item.glink1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.glink300px && item.glink1000px) {
                    handleImageClick(item.glink1000px);
                  }
                }}
              />

              <Image
                className='mt-2'
                src={
                  (item.g2link300px && item.g2link1000px)
                    ? item.g2link300px
                    : (item.g2link1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.g2link300px && item.g2link1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.g2link300px && item.g2link1000px) {
                    handleImageClick(item.g2link1000px);
                  }
                }}
              />
              <FullImageModal show={show} handleClose={handleClose} imageSrc={imageSrc} />
            </Col>

            <Col md={3} className="justify-content-left mt-2">
              <Image
                className='mt-2'
                src={
                  (item.g3link300px && item.g3link1000px)
                    ? item.g3link300px
                    : (item.g3link1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.g3link300px && item.g3link1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.g3link300px && item.g3link1000px) {
                    handleImageClick(item.g3link1000px);
                  }
                }}
              />

            <Image
                className='mt-2'
                src={
                  (item.g4link300px && item.g4link1000px)
                    ? item.g4link300px
                    : (item.g4link1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.g4link300px && item.g4link1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.g4link300px && item.g4link1000px) {
                    handleImageClick(item.g4link1000px);
                  }
                }}
              />
              <FullImageModal show={show} handleClose={handleClose} imageSrc={imageSrc} />
            </Col>

            <Col md={3} className="justify-content-left mt-2">
              <Image
                className='mt-2'
                src={
                  (item.g5link300px && item.g5link1000px)
                    ? item.g5link300px
                    : (item.g5link1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.g5link300px && item.g5link1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.g5link300px && item.g5link1000px) {
                    handleImageClick(item.g5link1000px);
                  }
                }}
              />

            <Image
                className='mt-2'
                src={
                  (item.g6link300px && item.g6link1000px)
                    ? item.g6link300px
                    : (item.g6link1000px ?? coming)
                }
                style={{
                  cursor: (
                    item.g6link300px && item.g6link1000px
                      ? "pointer"
                      : "default"
                  )
                }}
                onClick={() => {
                  if (item.g6link300px && item.g6link1000px) {
                    handleImageClick(item.g6link1000px);
                  }
                }}
              />
              <FullImageModal show={show} handleClose={handleClose} imageSrc={imageSrc} />
            </Col>

        </Row>
      </Col>
    </Container>
  );
}


export default RenderPage;
