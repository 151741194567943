import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { observer } from 'mobx-react-lite';
import { STAMP_ROUTE } from '../utils/consts';
import { useNavigate } from 'react-router-dom';
import { fetchItems } from '../http/stampAPI.js';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import {calculatePageSize} from '../utils/calcPageSize.js'

import { minMaxFilterEditor, minMaxFilterFunction } from '../components/filters/minMaxFilterTable.js';
import { minFilterEditor, minFilterFunction } from '../components/filters/minFilterTable.js';
import { maxFilterEditor, maxFilterFunction } from '../components/filters/maxFilterTable.js';
// import './TablePage.css';

const TablePage = observer(() => {
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFromFunction = await fetchItems(); // Вызов функции без передачи предварительных значений
        new Tabulator("#stamp-table", {
          data: dataFromFunction,
          layout : "fitDataStretch" ,
          // height:"650px",
          renderHorizontal:"virtual",
          rowHeights: "variable", 
          columnHeaderVertAlign:"bottom", //align header contents to bottom of cell "center""bottom"

          pagination:"local",
          paginationSize: calculatePageSize(),
          paginationSizeSelector:[3, 5, 8, 10, 12, 14, 16, 20, 30, 40, 50, 100],
          movableColumns:true,
          paginationCounter:"rows",

          columnHeaderVertAlign: "middle", // Выравнивание контента в заголовках



          columns: [
            { title: "ID", field: "model_id", width:65, frozen:true},
            { title: "Изображение", field: "xlink300px", frozen:true,
              formatter: (cell, formatterParams, onRendered) => {
                const value = cell.getValue();
                const content = value.startsWith('SF') ? 'нет' : `<img src="${value}" style="height :50px ;" />`;
            
                const cellElement = document.createElement('div');
                cellElement.innerHTML = content;
            
                cellElement.addEventListener('click', () => {
                  const id = cell.getRow().getData().id;
                  window.open(`${STAMP_ROUTE}/${id}`).focus();
                });
            
                return cellElement;
              },
              rowFormatter: (row) => {
                const data = row.getData();
                const hasImage = data.xlink300px && !data.xlink300px.startsWith('SF');
                if (!hasImage) {
                  row.getElement().style.height = 'auto';
                }
              },
            },

            { title: "Легенда", field: "stamp_legend", headerFilter:"input"},
            { title: "Сохранность", field: "stamp_preservation", hozAlign:"center",editor:"input", editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
            { title: "Расположние", field: "stamp_position", hozAlign:"center", editor:"input", editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
            //create column group
            { title:"Размер, мм",
                  columns:[
                  { title: "по оси X", field: "axis_large",sorter:"number", hozAlign:"center", 
                    headerFilter:minMaxFilterEditor, headerFilterFunc:minMaxFilterFunction, headerFilterLiveFilter:false},
                  { title: "по оси Y", field: "axis_small",sorter:"number", hozAlign:"center", 
                    headerFilter:minMaxFilterEditor, headerFilterFunc:minMaxFilterFunction, headerFilterLiveFilter:false},
                ],
              },
            // { title: "Размер", field: "axis_large" х "axis_small"},
            { title: "Эмблема", field: "emblem" , width:160, headerFilter:"input"},
            {//create column group
                  title:"Тип",
                  columns:[
                  { title: "рельефа", field: "relief_type", editor:"input", editable: false,
                    headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
                  { title: "содержания", field: "content_type", editor:"input",  editable: false,
                    headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
                  { title: "формы", field: "shape_type", editor:"input",  editable: false,
                    headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true }},
                  { title: "происхождения", field: "origin_type", editable: false,
                    headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
                  ],
            },

            // { title: "Датировка", field: "date_text" , width:160 },
            {title:"Дата",
                  columns:[
                  { title: "ранняя", field: "date_early",sorter:"number", hozAlign:"center", hozAlign:"center",
                   headerFilter:minFilterEditor, headerFilterFunc:minFilterFunction, headerFilterLiveFilter:false},
                  { title: "поздняя", field: "date_late",sorter:"number", hozAlign:"center", hozAlign:"center", 
                    headerFilter:maxFilterEditor, headerFilterFunc:maxFilterFunction, headerFilterLiveFilter:false},
                ],
              },

            {title:"Хронологическая группа",
                  columns:[
                  { title: "по Финкельштейну", field: "finkelstein", editor:"input", editable: false,
                    headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
                  { title: "по Гарлану", field: "garlan", editor:"input", editable: false,
                    headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
                  ],
                },
            { title: "Статус", field: "stamp_preservation_comm", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
            // { title: "Модель", field: "model_link" },
            // Артефакт
            { title: "Тип", field: "artif_g", hozAlign:"center", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Центр производства", field: "manufact_center", hozAlign:"center", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Сохранность артефакта", field: "artif_preservation", hozAlign:"center", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Цвет", field: "munsell_name", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Цветокод", field: "munsell_code", editor:"input", editable: false,
            headerFilter:"list", hozAlign:"center",  headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Магистрат", field: "magist_name", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Фабрикант", field: "fabric_name", editor:"input", editable: false,
            headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},

            // Памятник и хранение
            { title: "Памятник", field: "site_name", editor:"input", editable: false,
              headerFilter:"list",headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Руководитель", field: "leader_exc", editor:"input", editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Организация", field: "unit_exc", editor:"input",  editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Год", field: "year_exc", sorter:"number", hozAlign:"center", editor:"input", editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true}},
            { title: "Раскоп", field: "artif_position", hozAlign:"center", editor:"input", editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
            { title: "Полевой шифр", field: "field_id", headerFilter:"input"},
            { title: "Место хранения", field: "artif_depository", editor:"input", editable: false,
              headerFilter:"list",headerFilterParams:{valuesLookup:true, clearable:true} },
            { title: "Музейный шифр", field: "depository_id", headerFilter:"input" },
            { title: "Публикации", field: "published", editor:"input", editable: false,
              headerFilter:"list", headerFilterParams:{valuesLookup:true, clearable:true} },
          ],
          
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container fluid className="justify-content-center align-items-flex-start"
                style={{ width: '100vw', height: '100vh' }}>
      <Row className='mt-2 item-text'
          style={{ height: '100%' }}>
        <Col md={12}
              >
          <div id="stamp-table"></div>
        </Col>
      </Row>
    </Container>
  )
});

export default TablePage;





// // Получение данных из вашей функции (пусть это будет dataFromFunction)
// const dataFromFunction = await fetchItems(site_id, manufact_center, year_exc, artif_position, artif_g, munsell_name,
//       stamp_position, stamp_preservation, relief_type, content_type, shape_type, origin_type, date_early,
//       date_late, finkelstein, garlan);

// // Создание таблицы с использованием полученных данных
// var table = new Tabulator("#stamp-table", {
//     data: dataFromFunction, // Установка данных таблицы
//     columns: [
//         {title: "ID", field: "site_id"},
//         {title: "Центр пр-во-а", field: "manufact_center"},
//         {title: "Год", field: "year_exc"},
//         {title: "Предмет", field: "artif_g"},
//         {title: "Цвет", field: "munsell_name"},
//         {title: "Расположение", field: "stamp_position"},
//         {title: "Сохранность", field: "stamp_preservation"},
//     ],
// });

//   return (
//     <Container className="justify-content-center">
//       <Row className='mt-3 item-text'>


//         <Col md={12} >


//           <Container>
//             <div id="stamp-table"></div>
//           </Container>


//         </Col>
//       </Row>
//     </Container>
//   )
// })

// export default TablePage;



// import { stampItems } from '..components/StampList';

// import {fetchItems} from '../http/stampAPI';

// import StampList from '../components/StampList';


  //   fetchItems(site_id, manufact_id, year_exc, artif_position, artif_g, munsell_name,
  //     stamp_position, stamp_preservation, relief_type, content_type, shape_type, origin_type, date_early,
  //     date_late, finkelstein, garlan).then(data => stamp.setItems(data))
  // }, [stamp.selectedSite, stamp.selectedManufact, stamp.selectedYear, stamp.selectedLocation, stamp.selectedGroup, stamp.selectedColor,
  // stamp.selectedPosition, stamp.selectedPreservation, stamp.selectedRelief, stamp.selectedContent, stamp.selectedShape,
  // stamp.selectedOrigin, stamp.selectedEarly, stamp.selectedLate, stamp.selectedFinkelstein, stamp.selectedGarlan]);
  

  