export const calculatePageSize = () => {
    const windowWidth = window.innerWidth;
  
    // Рассчитываем количество элементов на странице в зависимости от ширины окна или других параметров
    if (windowWidth < 768) {
      return 5; // Небольшое окно - меньше элементов на странице
    } else if (windowWidth < 1800) {
      return 8; // Среднее окно - среднее количество элементов
    } else {
      return 12; // Большое окно - больше элементов
    }
  };