export const minFilterEditor = function(cell, onRendered, success, cancel, editorParams) {
  var container = document.createElement("div");

  // Создание поля для минимального значения
  var start = document.createElement("input");
  start.setAttribute("type", "number");
  start.setAttribute("placeholder", "Min");
  start.setAttribute("min", -550);
  start.setAttribute("max", 100);
  start.style.padding = "4px";
  start.style.width = "100%";
  start.style.boxSizing = "border-box";

  start.value = cell.getValue();

  function buildValues() {
      success({
          start: start.value,
      });
  }

  function keypress(e) {
      if (e.keyCode == 13) {
          buildValues();
      }

      if (e.keyCode == 27) {
          cancel();
      }
  }

  start.addEventListener("change", buildValues);
  start.addEventListener("blur", buildValues);
  start.addEventListener("keydown", keypress);

  container.appendChild(start);

  return container;
}

export const minFilterFunction = function(headerValue, rowValue, rowData, filterParams) {
    if (!headerValue.start) {
        return true; // Показывать все значения, если минимальное значение не установлено
    }

    if (rowValue !== null && rowValue !== undefined && !isNaN(rowValue)) {
        const start = parseFloat(headerValue.start);

        if (!isNaN(start)) {
            return rowValue >= start;
        }
    }
    return false;
};
