import React from 'react';
import { Col, Card, Form, Container} from 'react-bootstrap';
// import soon from '../assets/coming.png';
import { useNavigate } from 'react-router-dom';
import { STAMP_ROUTE } from '../utils/consts';

const StampItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      {item.xlink300px.startsWith('SF') ? null : (
        <Col md={3} className={"mt-2"} onClick={() => navigate(STAMP_ROUTE + '/' + item.id)}>
          <Card style={{ cursor: 'pointer', background: '#0b0b0b' }} text={'fcfcdb'}>
            <Card.Img
              src={item.xlink300px}
              alt="Card image"
            />
          </Card>
        </Col>
      )}
    </>
  );
};

export default StampItem;

// const StampItem = ({ item }) => {
//   const navigate = useNavigate();

//   return (

//     <Col md={3} className={"mt-2"} onClick={() => navigate(STAMP_ROUTE + '/' + item.id)}>
//       {item.xlink300px.startsWith('SF') ? null : ( // Проверка начала строки
//         <Card style={{ cursor: 'pointer', background: '#0b0b0b' }} text={'fcfcdb'}>
//           <Card.Img
//             src={item.xlink300px}
//             alt="Card image"
//           />
//         </Card>
//       )}
//     </Col>


// 2 was before UM asked to remove from tiles "COMING SDOON" - images
    // <Col md={3} className={"mt-2"} onClick={() => navigate(STAMP_ROUTE + '/' + item.id)}>
    //   {/* <Card style={{ width: 240, cursor: 'pointer', background:'#0b0b0b'}} text={'fcfcdb'} > */}
    //   <Card style={{ cursor: 'pointer', background:'#0b0b0b'}} text={'fcfcdb'} >
    //   <Card.Img 
    //   // width={240} height={170} 
    //             src={item.xlink300px.startsWith('SF') ? soon : item.xlink300px} 
    //             alt="Card image"
    //   />
    //         </Card>
    // </Col>

// 1 was when i start dev
      // {/* <Card.ImgOverlay>
      //   <Card.Title>{item.id}</Card.Title>
      // </Card.ImgOverlay> */}
      //   {/* <Image width={200} height={160} src={imageSrc} /> */}
      //   {/* <div>клеймо</div> */}



  // console.log(navigate);

  // const isXLink = image.link300px.endsWith('X.png');
  // const isSFLink = image.link300px.startsWith('SF');

  // if (!isXLink && !isSFLink) {
  //   return null;
  // }

  // const imageSrc = isXLink ? image.link300px : soon;

  // const isXLink = stamp.xlink300px.endsWith('X.png');
  // const isSFLink = stamp.xlink300px.startsWith('SF');

  // if (!isXLink && !isSFLink) {
  //   return null;
  // }

  // const imageSrc = isXLink ? stamp.xlink300px : soon;