export const maxFilterEditor = function(cell, onRendered, success, cancel, editorParams) {
    var container = document.createElement("div");

    // Создание поля для максимального значения
    var end = document.createElement("input");
    end.setAttribute("type", "number");
    end.setAttribute("placeholder", "Max");
    end.setAttribute("min", -600);
    end.setAttribute("max", 100);
    end.style.padding = "4px";
    end.style.width = "100%";
    end.style.boxSizing = "border-box";

    end.value = cell.getValue();

    function buildValues() {
        success({
            end: end.value,
        });
    }

    function keypress(e) {
        if (e.keyCode == 13) {
            buildValues();
        }

        if (e.keyCode == 27) {
            cancel();
        }
    }

    end.addEventListener("change", buildValues);
    end.addEventListener("blur", buildValues);
    end.addEventListener("keydown", keypress);

    container.appendChild(end);

    return container;
}

export const maxFilterFunction = function(headerValue, rowValue, rowData, filterParams) {
    if (!headerValue.end) {
        return true; // Показывать все значения, если максимальное значение не установлено
    }

    if (rowValue !== null && rowValue !== undefined && !isNaN(rowValue)) {
        const end = parseFloat(headerValue.end);

        if (!isNaN(end)) {
            return rowValue <= end;
        }
    }
    return false;
};
