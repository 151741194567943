import React, { useEffect, useState } from 'react';
import { Center } from 'react-bootstrap';
import { Container, Col, Button, Row, Image, Stack } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { BASE_ROUTE, MODEL_ROUTE, RENDER_ROUTE } from '../utils/consts';
import { useNavigate, useParams } from 'react-router-dom';
import FullImageModal from '../components/modals/FullImageModal';
import { fetchOneItem } from '../http/stampAPI';
import unready from '../assets/unready.png';

function StampPage() {
  const [item, setItem] = useState({
    site_name: [], leader_exc: [], unit_exc: [],
    year_exc: [], artif_position: [], field_id: [],
    artif_depository: [], depository_id: [],
    artif_g: [], description: [], stamp_location_description: [], artif_preservation: [],
    // munsell_hue: [], munsell_value: [], munsell_chroma: [],
    munsell_code: [], munsell_name: [],

    model_id: [], stamp_position: [], emblem: [], stamp_preservation: [],
    manufact_center: [], magist_name: [], fabric_name: [], date_text: [],
    relief_type: [], content_type: [], shape_type: [], origin_type: [],
    axis_large: [], axis_small: [],
    finkelstein: [], garlan: [], stamp_comments: [], published: [],
    xlink300px: '', xlink1000px: '', zlink1000px: '', zlink300px: '',
    glink1000px: '', glink300px: ''
  });

  const { id } = useParams();
  // console.log(params)

  useEffect(() => {
    fetchOneItem(id).then(data => setItem(data));
  }, []);


  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const handleClose = () => setShow(false);

  const handleImageClick = (src) => {
    setImageSrc(src);
    setShow(true);
  };

  // const SCALE_FACTOR = 1.13; ,добавляла чтоб риндеры были одинаковые раньше
  // const hasValidImageSource = item.glink300px && item.glink1000px;

  return (
    <Container className="d-flex justify-content-center align-items-flex-start"
      style={{ width: '100vw', height: '100vh' }}
    >
      <Col className='mt-2 md-4'>

        {/* <Row className='item-text'
          style={{ width: '100%' }}>

          <Col md={4} className="d-flex justify-content-left mt-2 ">
            <Button
              className=" mx-2"
              style={{ width: '210px' }}
              variant="outline-light"
              disabled={!item.model_link}
              // variant="dark"
              onClick={() => window.open(RENDER_ROUTE + '/' + item.id, '_blank').focus()}
            >
              Больше иллюстраций
            </Button>
            <Button
              // className="justify-content-right"
              style={{ width: '90px' }}
              variant="outline-light"
              disabled={!item.model_link}
              // variant="dark"
              onClick={() => navigate(MODEL_ROUTE + '/' + item.model_id)}
            // onClick={() => navigate(MODEL_ROUTE + '/' + model.model_id)} - если получать из отдельных табиц а не из общей
            >3D
            </Button>
          </Col>

          <Col md={4} className="justify-content-left mt-2">
            <Card className="d-flex"
              // border="secondary"
              border="light"
              style={{
                backgroundColor: "#0b0b0b"
                // , color: "#fdf0f0ec",
                , color: "#F8F9FA",
                borderWidth: "0px"
              }}
            >
              <Card.Header bg={'black'} text={'fcfcdb'} as="h5"><strong>ID:&nbsp; {item.model_id}</strong></Card.Header>
            </Card>
          </Col>

          <Col md={4} className="d-flex justify-content-center mt-2">
            <Button
              style={{ width: '300px' }}
              variant="outline-light"
              // variant="dark"
              onClick={() => navigate(BASE_ROUTE)}
            >
              Назад к БД
            </Button>
          </Col>

        </Row> */}

        <Row className='md-4'>
          <Col md={4} className="justify-content-center mt-2">
            {/* <h4 className="bold">{item.model_id} </h4> */}
            <Row>
              <Image
                className='mt-2'
                variant="top"
                src={
                  (item.xlink1000px && item.xlink300px && item.xlink1000px.startsWith('SF') && item.xlink300px.startsWith('SF'))
                    ? unready
                    : item.xlink300px
                }
                style={{
                  width: '80%',
                  // width: 310,
                  // height: 205,
                  cursor: (
                    item.xlink1000px && item.xlink300px && item.xlink1000px.startsWith('SF') && item.xlink300px.startsWith('SF')
                      ? 'default'
                      : 'pointer'
                  )
                }}
                onClick={() => {
                  if (!(item.xlink1000px && item.xlink300px && item.xlink1000px.startsWith('SF') && item.xlink300px.startsWith('SF'))) {
                    handleImageClick(item.xlink1000px);
                  }
                }}
              /><br />
            </Row>

            <Row>
              {item.zlink300px && item.zlink1000px && (
                <Image
                  className='mt-2'
                  src={item.zlink300px}
                  style={{
                    width: '80%',
                    cursor: (
                      item.zlink300px && item.zlink1000px
                        ? "pointer"
                        : "default"
                    )
                  }}
                  onClick={() => {
                    if (item.zlink300px && item.zlink1000px) {
                      handleImageClick(item.zlink1000px);
                    }
                  }}
                />
              )}
            </Row>

            <Row>
              {item.glink300px && item.glink1000px && (
                <Image
                  className='mt-2'
                  src={item.glink300px}
                  style={{
                    width: '80%',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    handleImageClick(item.glink1000px);
                  }}
                />
              )}
            </Row>

            <FullImageModal show={show} handleClose={handleClose} imageSrc={imageSrc} />

            {/* <Row className="d-flex">

              <Button
                className=" mx-2"
                style={{ width: '210px' }}
                variant="outline-light"
                disabled={!item.model_link}
                // variant="dark"
                onClick={() => window.open(RENDER_ROUTE + '/' + item.id, '_blank').focus()}
              >
                Больше иллюстраций
              </Button>

              <Button
                // className="justify-content-right"
                style={{ width: '90px' }}
                variant="outline-light"
                disabled={!item.model_link}
                // variant="dark"
                onClick={() => navigate(MODEL_ROUTE + '/' + item.model_id)}
              // onClick={() => navigate(MODEL_ROUTE + '/' + model.model_id)} - если получать из отдельных табиц а не из общей
              >3D
              </Button>
            </Row> */}

            {/* <Row>
            <Image
              className='mt-2'
              src={
                (item.glink300px && item.glink1000px)
                  ? item.glink300px
                  : (item.glink1000px ?? unready)
              }
              style={{
                width: '80%',
                cursor: (
                  item.glink300px && item.glink1000px
                    ? "pointer"
                    : "default"
                )
              }}
              onClick={() => {
                if (item.glink300px && item.glink1000px) {
                  handleImageClick(item.glink1000px);
                }
              }}
            />
          </Row> */}

            {/* <Stack gap={1} as="h6">
            <div className="p-2" style={{ lineHeight: '1.5' }}>
              {( item.munsell_code !== 'не определено') ? (
                    <>
                {item.munsell_code} {item.munsell_name}<br />
                </>
                ) : null
              }
              {( item.axis_small) ? (
                <>
                  <em>Размер: </em> 
                  {item.axis_large} × {item.axis_small} мм <br />
                </>
              ) : null
              }
                <em>Сохранность: </em> {item.stamp_preservation} <br />
              </div>
          </Stack> */}

            <Button
                className='mt-2'
                style={{ width: '80%' }}
                variant="outline-light"
                disabled={!item.model_link}
                onClick={() => window.open(RENDER_ROUTE + '/' + item.id, '_blank').focus()}
              >
                Больше иллюстраций
              </Button>

              <Button
                className='mt-2'
                style={{ width: '80%' }}
                variant="outline-light"
                disabled={!item.model_link}
                onClick={() => navigate(MODEL_ROUTE + '/' + item.model_id)}
              // onClick={() => navigate(MODEL_ROUTE + '/' + model.model_id)} - если получать из отдельных табиц а не из общей
              >3D
              </Button>

            <Button
              className='mt-2'
              style={{ width: '80%' }}
              variant="outline-light"
              // variant="dark"
              onClick={() => navigate(BASE_ROUTE)}
            >
              Назад к БД
            </Button>


          </Col>

          <Col md={5} className="justify-content-left mt-2">

            <div className="p-2"
                  style={{ lineHeight: '1.5' }}
                >
                  <h5><strong>ID:&nbsp; {item.model_id}</strong></h5>
            </div>

          
            <Stack gap={6} as="h6">
              <div className="p-2"
                style={{ lineHeight: '1.5' }}
              >
                <em>Легенда: &nbsp;</em> <strong>{item.stamp_legend}</strong><br />

                {(item.emblem !== "не определено" && item.emblem !== "нет") ? (
                  <>
                    <em>Эмблема: &nbsp;</em> {item.emblem}<br />
                  </>
                ) : null}

                <em>Центр производства: &nbsp;</em> {item.manufact_center}<br />

                {(item.magist_name !== "не определено") ? (
                  <>
                    <em>Магистрат: &nbsp;</em> {item.magist_name}<br />
                  </>
                ) : null}

                {(item.fabric_name !== "не определено") ? (
                  <>
                    <em>Фабрикант: &nbsp;</em> {item.fabric_name}<br />
                  </>
                ) : null}
              </div>

              <div className="p-2" style={{ lineHeight: '1.5' }}>
                <strong>Датировка</strong><br />
                <em>Период: &nbsp;</em> {item.date_text}<br />
                <em>по Финкельштейну: &nbsp;</em> {item.finkelstein}<br />
                <em>по Гарлану: &nbsp;</em> {item.garlan}<br />
              </div>

              <div className="p-2" style={{ lineHeight: '1.5' }}>
                <strong>Предмет</strong><br />
                <em>Описание: &nbsp;</em> {item.description}<br />

                {/* {(item.stamp_location_description !== '') ? (
                  <>
                    <em>Расположение клейма: &nbsp;</em>
                    {item.stamp_location_description}<br />
                  </>
                ) : null
                } */}

              </div>

              <div className="p-2" style={{ lineHeight: '1.5' }}>
                <strong>Найдено</strong><br />
                {item.site_name}, &nbsp; раскопки  {item.year_exc} г.<br />
                {item.leader_exc}, &nbsp; {item.unit_exc} <br />
                Раскоп {item.artif_position}, &nbsp; <em> полевой шифр: &nbsp;</em>  {item.field_id}<br />
              </div>

              <div className="p-2" style={{ lineHeight: '1.5' }}>
                <strong>Хранение</strong><br />
                {item.artif_depository},&nbsp; &nbsp; {item.depository_id}<br />
              </div>


              <div className="p-2" style={{ lineHeight: '1.5' }}>
                <strong>Классификационные параметры</strong><br />
                <em>По содержанию: &nbsp;</em>{item.content_type}<br />
                <em>По наличию эмблемы: &nbsp;</em> {item.emblem}<br />
                <em>По происхождению: &nbsp;</em> {item.origin_type}<br />
                <em>По рельефу: &nbsp;</em>{item.relief_type}<br />
                <em>По форме: &nbsp;</em>{item.shape_type}<br />
                <em>Положение на предмете: &nbsp;</em>{item.stamp_position}<br />
              </div>

              <div className="p-2" style={{ lineHeight: '1.5' }}>

                {(item.munsell_code !== 'не определено') ? (
                  <>

                    <em>Цвет: &nbsp;</em>{item.munsell_name}<br />
                    <em>Код цвета: &nbsp;</em> {item.munsell_code} <br />

                  </>
                ) : null
                }

                {(item.axis_small) ? (
                  <>
                    <em>Размер: &nbsp;</em>
                    {item.axis_large} × {item.axis_small} мм <br />
                  </>
                ) : null
                }

                <em>Сохранность: &nbsp;</em> {item.stamp_preservation} <br />

              </div>




            </Stack>

          </Col>

          <Col md={3}>
          </Col>



          {/* <Col md={4} className="justify-content-left mt-2">
          <Stack gap={6} as="h6">
              
                {( item.munsell_code !== 'не определено') ? (
                  <>
                    <div className="p-2" style={{ lineHeight: '1.5' }}>
                      {item.munsell_code} {item.munsell_name}<br />
                    </div>
                  </>
                  ) : null
                }
              
                {( item.axis_small) ? (
                  <>
                    <div className="p-2" style={{ lineHeight: '1.5' }}>
                      <em>Размер: </em> 
                      {item.axis_large} × {item.axis_small} мм <br />
                    </div>
                  </>
                ) : null
                }
            
              <div className="p-2" style={{ lineHeight: '1.5' }}>
                <em>Сохранность: </em> {item.stamp_preservation} <br />
              </div>

              <div className="p-2" style={{ lineHeight: '1.7' }}>
                    <strong>Классификационные параметры</strong><br />
                    <em>По содержанию:</em> {item.content_type}<br />
                    <em>По наличию эмблемы:</em> {item.emblem}<br />
                    <em>По происхождению:</em> {item.origin_type}<br />
                    <em>По рельефу:</em> {item.relief_type}<br />
                    <em>По форме:</em> {item.shape_type}<br />
                    <em>Положение на предмете:</em> {item.stamp_position}<br />
              </div>
          </Stack>
        </Col> */}

        </Row>

      </Col>

    </Container>
  )
}
export default StampPage

{/* <Col md={3} className="justify-content-left mt-2">
            <h4 className="bold">О клейме</h4>
            <Row className='d-flex flex-column m-1'>
              {list11.map((key) => {
                if (!item.hasOwnProperty(key)) {
                  return null;
                }
                return (
                  <Row key={key} className="table-row">
                    <Col style={{fontStyle: 'italic'}}>
                      {key === 'model_id'? 'ID' :
                        key === 'emblem' ? 'Эмблема' :
                          key === 'stamp_preservation' ? 'Сохранность' :
                            key === 'date_text' ? 'Датировка' :
                              key}
                    </Col>
                    <Col style={{ fontSize: '15px'}}>{item[key]}</Col>
                  </Row>
                );
              })}
            </Row>

            <Row className='d-flex flex-column m-1'>
              <Row className="table-row" 
                    style={{ fontWeight: 'bold', 
                            fontSize: '20px',
                            textShadow: '2px 2px 4px rgba(1, 0, 0, 0.5)',
                            textDecoration: 'underline' 
                            }}>
                <Col>Тип:</Col>
              </Row>
              {list12.map((key) => {
                if (!item.hasOwnProperty(key)) {
                  return null;
                }
                return (
                  <Row key={key} className="table-row">
                    <Col style={{fontStyle: 'italic'}}>
                      {key === 'relief_type' ? 'рельефа' :
                          key === 'content_type' ? 'содержания' :
                            key === 'shape_type' ? 'формы' :
                              key === 'origin_type' ? 'происхождения' :
                                  key}
                    </Col>
                    <Col style={{ fontSize: '15px'}}>{item[key]}</Col>
                  </Row>
                );
              })}
            </Row>

            <Row className='d-flex flex-column m-1'>
              <Row className="table-row" 
                    style={{ fontWeight: 'bold', 
                    fontSize: '19px',
                    textShadow: '2px 2px 4px rgba(1, 0, 0, 0.5)',
                    textDecoration: 'underline' 
                    }}>
                <Col>
                  Хронологическая группа:
                </Col>
              </Row>

              {list13.map((key) => {
                if (!item.hasOwnProperty(key)) {
                  return null;
                }
                return (
                  <Row key={key} className="table-row">
                    <Col style={{fontStyle: 'italic'}}>
                      {key === 'finkelstein' ? 'по Финкельштейну' :
                        key === 'garlan' ? 'по Гарлану' :
                          key}
                    </Col>
                    <Col style={{ fontSize: '15px'}}>{item[key]}</Col>
                  </Row>
                );
              })}
            </Row>
          </Col> */}

{/* <Col md={3} className="justify-content-left mt-2">
            <h4 className="bold">Предмет</h4>
            <Row className='d-flex flex-column m-1'>
              {list2.map((key) => {
                if (!item.hasOwnProperty(key)) {
                  return null;
                }
                return (
                  <Row key={key} className="table-row">
                    <Col style={{fontStyle: 'italic'}}>
                      {key === 'artif_g' ? 'Тип' :
                        key === 'manufact_center' ? 'Центр производства' :
                          key === 'artif_preservation' ? 'Сохранность' :
                            key === 'munsell_name' ? 'Цвет' :
                              key === 'munsell_code' ? 'Цветокод' :
                                key === 'magist_name' ? 'Магистрат' :
                                  key === 'fabric_name' ? 'Фабрикант' :
                                    key === 'stamp_position' ? 'Расположение клейма' :
                                      // key === 'axis_large' ? 'Размер ' :
                                      //   key === 'axis_small' ? 'Размер по малой оси' :
                                          key}
                    </Col>
                    <Col>
                      {item[key]}
                    </Col>
                  </Row>
                );
              })}
              <Row className="table-row">
              <Col style={{fontStyle: 'italic'}}>
                Размер, мм
              </Col>
              <Col>
                {item.axis_large ? `${item.axis_large}x${item.axis_small || 'неизвестно'}` : 'неизвестно'}
              </Col>
              </Row> 
            </Row>
          </Col> */}

{/* <Col md={3} className="justify-content-left mt-2">
            <h4 className="bold">Памятник и хранение</h4>
            <Row className='d-flex flex-column m-1'>
              {list3.map((key) => {
                if (!item.hasOwnProperty(key)) {
                  return null;
                }
                return (
                  <Row key={key} className="table-row">
                    <Col style={{fontStyle: 'italic'}}>
                      {key === 'site_name' ? 'Памятник' :
                        key === 'leader_exc' ? 'Руководитель' :
                          key === 'unit_exc' ? 'Организация' :
                            key === 'year_exc' ? 'Год' :
                              key === 'artif_position' ? 'Раскоп' :
                                key === 'field_id' ? 'Полевой шифр' :
                                  key === 'artif_depository' ? 'Место хранения' :
                                    key === 'depository_id' ? 'Музейный шифр' :
                                      key === 'published'? 'Публикации': 
                                          key}
                    </Col>
                    <Col style={{ fontSize: '14px' }}>{item[key]}</Col>
                  </Row>
                );
              })}
            </Row>
          </Col> */}



// artif_depository: [], artif_g: [], artif_position: [], artif_preservation: [],
// artifact_id: [], axis_large: [], axis_small: [], content_type: [], date_early: [], date_late: [], date_text: [],
// depository_id: [], emblem: [], fabric_name: [],
// field_id: [], finkelstein: [], frame_count: [], garlan: [], glink1000px: '', glink300px: '', leader_exc: [],
// magist_name: [], manufact_center: [], manufact_id: [], manufact_latitude: [], manufact_longitude: [],
// munsell_chroma: [], munsell_code: [], munsell_hue: [], munsell_name: [], munsell_value: [], origin_type: [],
// polygon_count: [], polygon_size: [], polygon_sm: [], relief_type: [], shape_type: [],
// site_id: [], site_name: [], stamp_comments: [], stamp_id: [], stamp_legend: [], stamp_legend_comment: [], stamp_position: [],
// stamp_preservation: [], stamp_preservation_comm: [], xlink300px: '', xlink1000px: '', year_exc: [], zlink1000px: '', zlink300px: '',
// model_id: []

// camera:[], // lens:[],  // model_date:[],// model_link:[],// model_process:[],site_latitude:[],// site_longitude:[],

{/* <Card border="darck"
          style={{ backgroundColor: "#0b0b0b" }}> */}
{/* <Card border="darck"
          style={{backgroundColor: "#0b0b0b"}}>
            <Card.Body>
              <Card.Title className="bold" as="h4">Общая информация</Card.Title>
              <Card.Text className='mt-3' as="h5">
                <p>Археологический памятник: {item.site_name}</p>
                <h5 className="underlined">Раскопки</h5>
                <p>руководитель: {item.leader_exc}</p>
                <p>организация: {item.unit_exc}</p>
                <p>год раскопок: {item.year_exc}</p>
                <p>место находки: {item.artif_position}</p>
                <p>полевой шифр: {item.field_id}</p>
                <h5 className="underlined">Хранение</h5>
                <p>место: {item.artif_depository}</p>
                <p>инвентарный номер: {item.depository_id}</p>
                <h5 className="underlined">Артефакт</h5>
                <p>предмет: {item.artif_g}</p>
                <p>описание: {item.description}</p>
                <p>сохранность: {item.artif_preservation}</p>
                <h5 className="underlined">Цвет</h5>
                <p>тон: {item.munsell_hue}</p>
                <p>значение: {item.munsell_value}</p>
                <p>хром: {item.munsell_chroma}</p>
                <p>код по Манселлу: {item.munsell_code}</p>
                <p>название по Манселлу: {item.munsell_name}</p>
              </Card.Text>
            </Card.Body>
          </Card> */}


{/* <Card border="darck" bg="black">
          <Card.Body>
            <Card.Title className="bold" as="h4">О клейме</Card.Title>
            <Card.Text className='mt-3' as="h5">
              <p>Идентификатор клейма: {item.model_id}</p>

              <p>расположние: {item.stamp_position}</p>
              <p>эмблема: {item.emblem}</p>
              <p>сохранность: {item.stamp_preservation}</p>

              <p>центр производства: {item.manufact_center}</p>
              <p>магистрант: {item.magist_name}</p>
              <p>фабрикант: {item.fabric_name}</p>
              <p>датировка: {item.date_text}</p>

              <h5 className="underlined">Тип по:</h5>
              <p>рельефу: {item.relief_type}</p>
              <p>содержанию: {item.content_type}</p>
              <p>форме:{item.shape_type}</p>
              <p>происхождению: {item.origin_type}</p>

              <h5 className="underlined">Размер клейма:</h5>
              <p>по большой оси: {item.axis_large}</p>
              <p>по малой оси: {item.axis_small}</p>

              <h5 className="underlined">Xронологическая группа:</h5>
              <p>по Финкельштейну:{item.finkelstein}</p>
              <p>по Гарлану:{item.garlan}</p>


            </Card.Text>
          </Card.Body>
        </Card> */}





// const item = {
//   artif_depository: "Восточно крымский...", artif_g: "амфора", artif_position: "P-1", artif_preservation: "частичная",
//   artifact_id: 354, axis_large: 10, axis_small: 5, date_early: -300, date_late: -100, date_text: "300 - 100 гг. днэ",
//   depository_id: "КП-189128", description: "описание артефакта", emblem: "афина на орле", fabric_name: "мистер фабрикант",
//   field_id: "ПАБ-15.1.73.к.о.32", finkelstein: "IIIa", frame_count: 37, garlan: "V МГ(А)", id: 356, image_description: null,
//   image_id: 1, image_type: null, leader_exc: "Ольховский С. В.", lens: "SEL90M28G",
//   link1000px: "https://rssdabase.su/RSSDA/imagery/ar/1000px/AR0535X.png",
//   link300px: "https://rssdabase.su/RSSDA/imagery/ar/300px/AR0535X.png",
//   Zlink1000px: "https://rssdabase.su/RSSDA/imagery/ar/1000px/AR0535Z.png",
//   Glink1000px: "https://rssdabase.su/RSSDA/imagery/ar/1000px/AR0535AS_GrSc-01.png",
//   magist_name: null, manufact_center: "Родос", manufact_id: 9, manufact_latitude: "36.16700", manufact_longitude: "28.00000",
//   munsell_chroma: 4, munsell_code: "2.5Y 6/4", munsell_hue: "2.5Y", munsell_name: "Light Yellowish Brown",
//   munsell_value: 6, origin_type: null, relief_type: "позитивное", shape_type: "подпрямоугольное",
//   site_id: 2, site_latitude: "45.26950", site_longitude: "36.96620", site_name: "Бухта Ак-Бурун",
//   stamp_comments: null, stamp_id: 356, stamp_legend_comment: null, stamp_position: "ручка", stamp_preservation: "полная",
//   stamp_preservation_comm: "Легенда плохо отпечаталась, имя не определяется.",
//   stamp_legend: "[ΑΣΤΥΝΟΜΟΥΝΤΟΣ] [ΠΟΣΙ]ΔΕΙ[ΟΥ] [ΤΟΥ ΘΕ]ΑΡΙΩΝΟΣ [ΗΡΑΚΛΕΙΔ]ΗΣ",
//   unit_exc: "Подводный отряд ИА РАН", year_exc: 2015,
//   model_id: "SF0001"
// };
// !!!!НЕНУЖНЫ НА ЭТОЙ СТРАНИЦЕ
// model_link: "https://rssda.su/auxil/ar0535.html",
// model_process: "фотограмметрия",
// model_date: "2021-06-07",
// polygon_count: 70 000,
// polygon_size: null,
// polygon_sm: null,
// camera: "Sony A7RII",
// content_type: null,

{/* <Row className='mt-2'>
            <Image width={250} height={190} src={item.link1000px} />
          </Row> */}
{/* <Row className='mt-2'>
            <Image width={250} height={190} src={item.Zlink1000px} />
          </Row>
          <Row className='mt-2'>
            <Image width={250} height={190} src={item.Glink1000px} />
          </Row> */}
{/* <Row className='mt-2'>
          <Card border="secondary" bg={'black'} text={'fcfcdb'} style={{ width: 400, height: 150 }}>
            <Card.Header as="h5">Восстановленная легенда</Card.Header>
            <Card.Body>
              <Card.Text>
                {item.stamp_legend}
              </Card.Text>
              <Button variant="secondary">Go TO MODEL!!!!ADD</Button>
            </Card.Body>
          </Card>
        </Row> */}