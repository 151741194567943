
import { Container, Card } from "react-bootstrap";

function About() {
  return (
    <Container className="d-flex justify-content-center">
      <Card style={{ background: "#212529ab", marginBottom: "10px" }} className="p-4 p-md-4 mt-3">
        <Card.Body style={{ color: "#FDF0F0EC", fontSize: "1.2rem", fontFamily: "Arial, sans-serif" }}>
          <div className="mb-3">
            <p>
              «Цифровой корпус амфорных клейм» является одним из результатов выполнения проекта РНФ № 22-28-02058 «Разработка методики массового бесконтактного документирования амфорных клейм» (2022-2023 гг.).
              Основной массив документированных клейм происходит из материалов подводных спасательных раскопок 2015-2017 гг. на ОАН «Бухта Ак-Бурун», ныне хранящихся в фондах ГБУ РК «Восточно-Крымский историко-культурный музей-заповедник». <br />
              В работах по проекту приняли участие <em> Ю.М. Свойский (RSSDA), С.В. Ольховский (ИА РАН), Е.В. Романенко (RSSDA), А.В. Зайцев (RSSDA), А.П. Гирич (RSSDA), А.П. Глотова (RSSDA), А.Н. Уральская (RSSDA), П.А. Мосалева (RSSDA), М.Д. Дынин (RSSDA), Д.В. Тронин (RSSDA), А.А. Зиганшина (RSSDA), А.А. Чернухина (RSSDA), М.А. Никитина (ИА РАН), Е.Р. Бирюкова (RSSDA), А.В. Смокотина (КФУ им. Вернадского). </em> <br />
              Представленные в Корпусе идентификации клейм выполнены <em> А.Б. Колесниковым (ИА РАН) и Е.В. Болонкиной (ГБУ РК «Восточно-Крымский историко-культурный музей-заповедник»).</em>
            </p>
          </div>

          <div className="mb-3">
            <p>
              Описание методики документирования амфорных клейм и способов решения некоторых актуальных проблем керамической эпиграфики опубликовано в следующих статьях:<br />
            </p>
          </div>

          <div className="mb-3">
            <div >
              <p>
                <em> Свойский Ю.М., Ольховский С.В., Романенко Е.В., Зайцев А.В.</em> <br /> 
                <strong> О методике массового бесконтактного документирования амфорных клейм. </strong><br /> 
                Краткие сообщения Института археологии. 2023. №270. С. 370-383.<br />
              </p>
            </div>

            <div className="mb-2">
              <p>
                <em> Свойский Ю.М., Ольховский С.В., Романенко Е.В., Зайцев А.В., Гирич А.П.</em> <br />
                <strong>Применение алгоритма мультимасштабного интегрального инварианта при исследовании амфорных клейм. </strong> <br /> 
                Археология евразийских степей. 2024 [в печати]<br />
              </p>
            </div>

            <div className="mb-2">
              <p>
                <em>Свойский Ю.М., Ольховский С.В., Романенко Е.В., Зайцев А.В., Гирич А.П., Глотова А.П.</em> <br />
                <strong> Амфорные клейма. Проблемы документирования, публикации, информатизации. </strong> <br />
                Вестник древней истории. 2024 [в печати]<br />
              </p>
            </div>

          </div>

          
        </Card.Body>
      </Card>
    </Container>
  );
}

export default About;